import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

import { ActivityTicker } from "src/shared/components/activity-ticker/activity-ticker";

import { track } from "../../../../util/segment";
import { featureFlagNames } from "src/shared/util/feature-flags";

import styles from "./styles.scss";

export const JobFamilyNavigation: FunctionComponent = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const activityTickerActive = useFeatureIsOn(featureFlagNames.activityTicker);

  const container = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (container.current === null) {
        return;
      }

      const currentScrollPos = window.pageYOffset;

      if (prevScrollPos > currentScrollPos) {
        container.current.style.marginTop = "-1px";
      } else {
        container.current.style.marginTop = "-200px";
      }

      setPrevScrollPos(currentScrollPos);
    };

    if (container.current) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const trackCategory = (title: string) => {
    track("Clicked Nav", {
      title,
    });
  };

  return (
    <div ref={container} className={styles.jobFamilyNavigation}>
      <Link
        onClick={() => trackCategory("data category")}
        to={"/topics/2e668564-bb43-41e7-a962-3d6ce401567c/"}
      >
        Data
      </Link>
      <Link
        onClick={() => trackCategory("people category")}
        to={"/topics/e7cb4c87-cab2-4e32-88b1-98e79b4eb4f0/"}
      >
        People
      </Link>
      <Link
        onClick={() => trackCategory("product category")}
        to={"/topics/73b6adeb-a761-4926-bc7c-238ab69a8737/"}
      >
        Product
      </Link>
      <Link
        onClick={() => trackCategory("sales category")}
        to={"/topics/9531509e-6af6-419a-ac59-3accf2a091da/"}
      >
        Sales
      </Link>
      <Link
        onClick={() => trackCategory("software-engineering category")}
        to={"/topics/cb65ba2e-9867-415f-a478-6b3f5a1d068d/"}
      >
        Software Engineering
      </Link>
      <Link to={""}>|</Link>
      <Link
        onClick={() => trackCategory("all category")}
        to={"/explore/topics/"}
      >
        More
      </Link>
      {activityTickerActive && (
        <div className={styles.activityTickerContainer}>
          <ActivityTicker />
        </div>
      )}
    </div>
  );
};
